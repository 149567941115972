import {UnloadData} from './unload-data.model';

export class Unload {
    id: number;
    order_id: number;
    ticket_number: string;
    offload_id: number;
    offload_name: string;
    offload_number: string;
    terminal_id: number;
    terminal_name: string;
    refinery_name: string;
    corrected_at: string;
    corrected_by: number;
    unload_data: UnloadData;
    driver_id: string;
    driver_name: string;
    v3_document_url: string;
}
